import { SCOOTER_CATEGORY_ID } from '#/shared/constants';

export const INTL_IDS_KEY = 'triMetIntlIds';
export const EXCLUSIVE_CATEGORY_TYPE = 'exclusive';

const bicycleOptions = [
  {
    mode: 'BICYCLE',
    image: 'https://maps.trimet.org/svgs/personalbike-white.svg',
    [INTL_IDS_KEY]: {
      label: 'bicycle-mode-label'
    }
  },
  {
    mode: 'BICYCLE_RENT',
    company: 'BIKETOWN',
    label: 'BIKETOWN',
    image: 'https://maps.trimet.org/svgs/biketown-white.svg',
    url: 'https://www.biketownpdx.com'
  }
];

/**
 * A config object for `otp-ui` components, the schema for this object is
 * defined here: https://github.com/opentripplanner/otp-ui/blob/master/packages/types/src/index.ts#L143
 */
export default {
  transitOperators: [
    {
      agencyId: 'TRIMET',
      feedId: 'TriMet',
      name: 'TriMet'
    }
  ],
  dateTime: {
    timeFormat: 'h:mmaaa',
    dateFormat: 'MMM dd, yyyy'
  },
  modes: {
    transitModes: [
      {
        mode: 'BUS',
        [INTL_IDS_KEY]: {
          label: 'bus-mode-label',
          shortName: 'bus-mode-short-name'
        }
      },
      {
        mode: 'TRAM',
        [INTL_IDS_KEY]: {
          label: 'light-rail-mode-label',
          shortName: 'light-rail-mode-short-name'
        }
      },
      {
        mode: 'RAIL',
        [INTL_IDS_KEY]: {
          label: 'rail-mode-label',
          shortName: 'rail-mode-short-name'
        }
      },
      {
        mode: 'GONDOLA',
        hidden: true,
        [INTL_IDS_KEY]: {
          label: 'bus-mode-label'
        }
      }
    ],
    categories: [
      {
        id: 'BICYCLE',
        type: 'access',
        options: bicycleOptions,
        [INTL_IDS_KEY]: {
          description: 'bicycle-and-transit-description',
          label: 'bicycle-and-transit-label'
        }
      },
      {
        id: 'CAR',
        type: 'access',
        [INTL_IDS_KEY]: {
          description: 'car-and-transit-description',
          label: 'car-and-transit-label'
        },
        options: [
          {
            mode: 'CAR_PARK',
            image: 'https://maps.trimet.org/svgs/parkandride-white.svg',
            [INTL_IDS_KEY]: {
              label: 'park-and-ride-mode-label'
            }
          }
        ]
      },
      {
        id: SCOOTER_CATEGORY_ID,

        type: 'access',
        mode: 'MICROMOBILITY_RENT',
        [INTL_IDS_KEY]: {
          description: 'e-scooter-and-transit-description',
          label: 'e-scooter-and-transit-label'
        },
        options: [
          {
            label: 'Lime',
            company: 'LIME',
            image: 'https://maps.trimet.org/svgs/lime-white.svg',
            url: 'https://www.li.me/en-US/home'
          },
          {
            label: 'Spin',
            company: 'SPIN',
            image: 'https://maps.trimet.org/svgs/spin-white.svg',
            url: 'https://www.spin.app/'
          }
        ]
      },
      {
        id: 'WALK',
        mode: 'WALK',
        type: EXCLUSIVE_CATEGORY_TYPE,
        [INTL_IDS_KEY]: {
          label: 'walk-only-label'
        }
      },
      {
        id: 'BICYCLE',
        type: EXCLUSIVE_CATEGORY_TYPE,
        options: bicycleOptions,
        [INTL_IDS_KEY]: {
          label: 'bicycle-only-label'
        }
      }
    ]
  },

  // A list of private transportation operators. These are either companies
  // that provide rental vehicles or transportation network companies. Companies
  // that have multiple modes of transport should have all modes listed as a
  // string with commas (e.g., BICYCLE_RENT, MICROMOBILITY_RENT, ...)
  companies: [
    {
      id: 'BIKETOWN',
      label: 'Biketown',
      modes: 'BICYCLE_RENT',
      mapSymbols: [
        {
          maxZoom: 13,
          minZoom: 0,
          type: 'circle',
          pixels: 4,
          fillColor: '#FF2E28',
          dockStrokeColor: '#000000'
        },
        {
          maxZoom: 17,
          minZoom: 14,
          type: 'circle',
          pixels: 6,
          fillColor: '#FF2E28',
          dockStrokeColor: '#000000'
        },
        {
          maxZoom: 999,
          minZoom: 18,
          type: 'hubAndFloatingBike'
        }
      ]
    },
    {
      id: 'LIME',
      label: 'Lime',
      modes: 'MICROMOBILITY_RENT',
      mapSymbols: [
        {
          maxZoom: 13,
          minZoom: 0,
          type: 'circle',
          pixels: 4,
          fillColor: '#656565',
          strokeColor: '#CCCCCC'
        },
        {
          maxZoom: 17,
          minZoom: 14,
          type: 'circle',
          pixels: 6,
          fillColor: '#656565',
          strokeColor: '#CCCCCC'
        },
        {
          maxZoom: 999,
          minZoom: 18,
          fillColor: '#656565',
          type: 'marker'
        }
      ]
    },
    {
      id: 'SPIN',
      label: 'Spin',
      modes: 'MICROMOBILITY_RENT',
      mapSymbols: [
        {
          maxZoom: 13,
          minZoom: 0,
          type: 'circle',
          pixels: 4,
          fillColor: '#656565',
          strokeColor: '#CCCCCC'
        },
        {
          maxZoom: 17,
          minZoom: 14,
          type: 'circle',
          pixels: 6,
          fillColor: '#656565',
          strokeColor: '#CCCCCC'
        },
        {
          maxZoom: 999,
          minZoom: 18,
          fillColor: '#656565',
          type: 'marker'
        }
      ]
    },
    {
      id: 'ZIPCAR',
      label: 'Zipcar',
      modes: 'CAR_RENT',
      mapOnly: true
    }
  ]
};
