import React, { useEffect, useState } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { withPrefix } from 'gatsby';
import { IntlProvider } from 'react-intl';
import { flatten } from 'flat';
import PropTypes from 'prop-types';
import store from '#/state/store';
import { ENGLISH_LOCALE_CODE } from '#/shared/constants';
import { enStrings } from '../static/intl';

// this component exists for use in the `wrapRootElement` because hooks can't be
// used directly in that function: https://github.com/gatsbyjs/gatsby/issues/22833
function RootElementWrapper({ element }) {
  // use english strings as starting value for SSR
  const [messages, setMessages] = useState(() => flatten(enStrings));
  const [locale, setLocale] = useState(ENGLISH_LOCALE_CODE);

  useEffect(() => {
    // once the built application hits Smartling's GDN this attribute
    // is added to indicate the language the user requested
    const nextLocale = document.body.getAttribute('data-smartling-locale');

    if (nextLocale && nextLocale !== ENGLISH_LOCALE_CODE) {
      // `strings.json` will be served up in the user's selected language
      // via Smartling's GDN
      fetch(withPrefix('/intl/strings.json'), {
        method: 'GET',
        // ensure that the strings file is not cached by the browser since
        // it can be updated at any time with new translations via Smartling
        headers: {
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache'
        }
      })
        .then(response => response.json())
        .then(stringsJson => {
          setMessages(flatten(stringsJson));
          setLocale(nextLocale);
        })
        .catch(error =>
          console.error(
            'Error fetching JSON file containing user-facing strings:',
            error
          )
        );
    }
  }, []);

  return (
    // Redux approach derived from Gatsby docs:
    // https://www.gatsbyjs.com/docs/adding-redux-store/
    <ReduxProvider store={store}>
      <IntlProvider
        locale={locale}
        defaultLocale={ENGLISH_LOCALE_CODE}
        messages={messages}
      >
        {element}
      </IntlProvider>
    </ReduxProvider>
  );
}

RootElementWrapper.propTypes = {
  element: PropTypes.element.isRequired
};

const wrapRootElement = ({ element }) => {
  return <RootElementWrapper element={element} />;
};

export default wrapRootElement;
