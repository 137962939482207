/**
 * Within the OTP itineraries many IDs are strings in the format:
 * `<agency-name>:<id-value>`, this function parses out the `id-value` which is
 * often needed to lookups on other transit data with in the app. Keys that hold
 * values in this format include `tripId`, `routeId` and `stopId`
 *
 * @param {string} idString ID string to be parsed
 * @param {boolean} [asNumber] Pass `true` to cast the return value to Number
 * @returns {number|string|null}
 */
export const parseOtpId = (idString, asNumber = false) => {
  if (!idString) {
    return null;
  }

  const idParts = idString.split(':');

  if (idParts.length === 2 && idParts[0] !== 'TriMet') {
    console.warn('Data from agency other than TriMet is being accessed');
  }

  const parsedId = idParts.pop();
  return asNumber ? Number(parsedId) : parsedId;
};

export default parseOtpId;
