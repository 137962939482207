import { isRejectedWithValue } from '@reduxjs/toolkit';
import { throttle, uniq } from 'lodash-es';
import { SOFT_TOAST_INTERVAL, THROTTLE_WINDOW } from '#/shared/constants';
import { dismissRequestError, requestErrorThrown } from '#/shared/slices/error';

let actionLog = [];
let actionType;
const throttledDispatch = throttle(
  api => {
    api.dispatch(requestErrorThrown());
    actionLog = [];
  },
  SOFT_TOAST_INTERVAL,
  { leading: true, trailing: false }
);

const rtkQueryErrorHandler = api => next => action => {
  const state = api.getState();
  const { requestError } = state.shared.error;
  if (isRejectedWithValue(action) && action.payload.status === 'FETCH_ERROR') {
    const { endpointName, type } = action.meta.arg;
    actionType = type;
    actionLog.push(endpointName);
    const uniqueSet = uniq(actionLog);

    if (actionType === 'query' && !requestError && uniqueSet.length >= 2) {
      setTimeout(() => {
        throttledDispatch(api);
      }, THROTTLE_WINDOW);
    }
  } else if (
    actionType === 'query' &&
    requestError &&
    actionLog.length > 0 &&
    action?.meta?.baseQueryMeta?.response?.status === 200
  ) {
    actionLog = [];
    api.dispatch(dismissRequestError());
  }

  return next(action);
};

export default rtkQueryErrorHandler;
