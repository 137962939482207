import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import logger from 'redux-logger';
import { developerApi } from '#/services/developerApi';
import { mapsApi } from '#/services/mapsApi';
import rootReducer from './rootReducer';
import rtkQueryErrorHandler from './middleware/rtk-query-error-handler';

const middlewareList = [
  developerApi.middleware,
  mapsApi.middleware,
  rtkQueryErrorHandler
];

if (process.env.NODE_ENV === 'production') {
  middlewareList.push(logger);
}

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(middlewareList),
  devTools: process.env.NODE_ENV !== 'production'
});

// Required for refetchOnFocus/refetchOnReconnect behaviors
setupListeners(store.dispatch);

export default store;
