import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  hasError: false,
  requestError: false,
  lastDismissed: null
};

const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    errorThrown(state) {
      state.hasError = true;
    },
    requestErrorThrown(state) {
      state.requestError = true;
    },
    dismissRequestError(state) {
      state.requestError = false;
    }
  }
});

export const { errorThrown, requestErrorThrown, dismissRequestError } =
  errorSlice.actions;

export default errorSlice.reducer;
